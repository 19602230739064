/* eslint-disable @typescript-eslint/no-explicit-any */
import reduce from 'lodash/reduce';
import includes from 'lodash/includes';
import ky from 'ky';

// HOOKS beforeRequest
import { KyInstance } from 'ky/distribution/types/ky';
import LanguageHook from './hooks/beforeRequest/language';
import AuthorizationHook from './hooks/beforeRequest/authorization';
import RefreshBeforeHook from './hooks/beforeRequest/refresh';

// HOOKS afterResponse
import RefreshAfterHook from './hooks/afterResponse/refresh';
import SentryLogger from './hooks/afterResponse/sentry';

// Конфигурация API
import config from './config.json';
import apiConfig from '../../config/api.json';
import { IApiRequestItem, IResponse, IWideKyOptions } from './types';
import recaptcha from './hooks/afterResponse/recaptcha';

interface IUrlDynamicParams {
    [key: string]: string | number;
}

type ReuqestConfigParams = IWideKyOptions & IApiRequestItem;

interface IRequestParams
    extends Pick<
        IWideKyOptions,
        'ignoreAuth' | 'requiredToken' | 'ignoreRefresh' | 'withoutReturn' | 'optionalAuth'
    > {
    urlParams?: IUrlDynamicParams;
    params?: {
        [key: string]: any;
    };
    body?: {
        [key: string]: any;
    };
    headers?: {
        [key: string]: string;
    };
}

interface IRequest {
    [key: string]: <T>(params: IRequestParams) => Promise<IResponse<T>>;
}

type IApiRequest = {
    [key in keyof typeof config]: IRequest;
};

const NO_BODY_METHODS = ['GET', 'HEAD'];

let kyExtended: KyInstance = ky.extend({
    headers: {
        'X-Api-Version': '2',
    },
    retry: 1,
    credentials: 'include',
    prefixUrl: process.env.NEXT_PUBLIC_API_PREFIX,
});

kyExtended = kyExtended.extend({
    hooks: {
        beforeRequest: [LanguageHook, RefreshBeforeHook(kyExtended), AuthorizationHook],
        afterResponse: [RefreshAfterHook(kyExtended), recaptcha(kyExtended), SentryLogger],
    },
});

/**
 * Функиця переобразующая динамический url
 * @param {string} url
 * @param {IUrlDynamicParams} urlParams
 * @returns {string}
 */
const generateUrl = (url: string, urlParams: IUrlDynamicParams = {}): string => {
    if (typeof url !== 'string') return url;
    return url.replace(/:([\w]+)/gi, (_match, param: string | undefined) => {
        if (!param) return undefined as any;
        return urlParams[param];
    });
};

/**
 * Функция, которая конвертирует конфиг метода в запрос
 * @param {ReuqestConfigParams} options
 * @returns {IRequest}
 */
const convertMethodConfigToRequest = ({
    name,
    method,
    url,
    params: defaultParams,
    ignoreAuth: defaultIgnoreAuth,
    requiredToken: defaultRequiredToken,
    headers: defaultHeaders,
    timeout,
}: ReuqestConfigParams) => ({
    [name]: ({
        urlParams = {},
        body = {},
        headers = {},
        params = defaultParams,
        ignoreAuth = defaultIgnoreAuth,
        requiredToken = defaultRequiredToken,
        optionalAuth = false,
    } = {}) =>
        kyExtended(generateUrl(url, urlParams), {
            method,
            timeout: timeout || apiConfig.DEFAULT_KY_TIMEOUT_IN_MS,
            headers: { ...defaultHeaders, ...headers },
            searchParams: { ...defaultParams, ...params },
            ...(!includes(NO_BODY_METHODS, method) && { json: body }),
            ignoreAuth,
            requiredToken,
            optionalAuth,
        } as IWideKyOptions).then(res => {
            if (res.headers.has('Content-Type')) {
                return res.headers.get('Content-Type').split(' ')[0] !== 'application/json;'
                    ? res
                    : res.json();
            }

            return res;
        }),
});

// Собираем API из конфига
// Первый шаг, собираем ресурсы
const api = reduce(
    config,
    (apiObj, resourceMethods, resourceName) => ({
        ...apiObj,
        [resourceName]: {
            // Собираем методы ресурса
            ...reduce(
                resourceMethods,
                (endpoints, method) => ({
                    ...endpoints,
                    ...convertMethodConfigToRequest(method as ReuqestConfigParams),
                }),
                {},
            ),
        },
    }),
    {} as IApiRequest,
);

export default api;
