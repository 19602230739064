import Header from './Header';
import Footer from './Footer';
import Alerts from './Alerts';

import { ILayoutProps } from './default';

const AffiliateLayout: React.FC<ILayoutProps> = ({ children, isAuth }) => {
    return (
        <>
            <Header isAuth={isAuth} className="!mb-0" />
            {children}
            <Footer />
            {isAuth && <Alerts />}
        </>
    );
};

export default AffiliateLayout;
