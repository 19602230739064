import routes from '@config/routes.json';
import { BurgerMenuItemTypes, IBurgerMenuItem } from './burgerMenu.d';

import newIcon from '../Navbar/img/new.svg';

const oldCabinetLinks = JSON.parse(process.env.NEXT_PUBLIC_OLD_CABINET_LINKS);

export const authItems = {
    epn: [
        {
            key: 'Statistic',
            type: BurgerMenuItemTypes.dropdown,
            id: 'MenuMobileStatisticDropdown',
            values: [
                {
                    key: 'General',
                    id: 'MenuMobileCommonStatsLink',
                    href: routes.statistics.common,
                },
                {
                    key: 'Transactions',
                    id: 'MenuMobileTransactionStatsLink',
                    href: routes.statistics.transactions,
                },
            ],
        },
        {
            key: 'Tools',
            type: BurgerMenuItemTypes.link,
            id: 'MenuMobileToolsLink',
            href: routes.creatives,
        },
        {
            key: 'My Traffic Sources',
            type: BurgerMenuItemTypes.link,
            id: 'MenuMobileTrafficSourcesLink',
            href: routes.placements.list,
        },
        {
            key: 'Affiliate Programs',
            type: BurgerMenuItemTypes.link,
            id: 'MenuMobileOffersLink',
            href: routes.offers,
        },
        {
            key: 'Support',
            type: BurgerMenuItemTypes.link,
            id: 'MenuMobileSupportLink',
            href: routes.support.list.replace(':status', 'open'),
        },
        {
            key: 'FAQ',
            type: BurgerMenuItemTypes.link,
            id: 'MenuMobileFaqLink',
            href: oldCabinetLinks.faq,
        },
        {
            key: 'Learning',
            type: BurgerMenuItemTypes.link,
            id: 'MenuMobileLearningLink',
            href: routes.faq.learning,
        },
        {
            type: BurgerMenuItemTypes.dropdown,
            key: 'Cryptovertical',
            id: 'MenuCryptoDropdown',
            icon: newIcon,
            values: [
                {
                    key: 'Webmasters',
                    id: 'Webmasters',
                    href: routes.crypto,
                },
                {
                    key: 'Advertiser',
                    id: 'Advertiser',
                    href: routes.cryptoadvertiser,
                },
            ],
        },
    ],
    epncpa: [
        {
            key: 'Statistic',
            type: BurgerMenuItemTypes.dropdown,
            id: 'MenuMobileStatisticDropdown',
            values: [
                {
                    key: 'General',
                    id: 'MenuMobileCommonStatsLink',
                    href: routes.statistics.common,
                },
                {
                    key: 'Transactions',
                    id: 'MenuMobileTransactionStatsLink',
                    href: routes.statistics.transactions,
                },
            ],
        },
        {
            key: 'Tools',
            type: BurgerMenuItemTypes.link,
            id: 'MenuMobileToolsLink',
            href: routes.creatives,
        },
        {
            key: 'My Traffic Sources',
            type: BurgerMenuItemTypes.link,
            id: 'MenuMobileTrafficSourcesLink',
            href: routes.placements.list,
        },
        {
            key: 'Affiliate Programs',
            type: BurgerMenuItemTypes.link,
            id: 'MenuMobileOffersLink',
            href: routes.offers,
        },
        {
            key: 'Support',
            type: BurgerMenuItemTypes.link,
            id: 'MenuMobileSupportLink',
            href: routes.support.list.replace(':status', 'open'),
        },
    ],
}[process.env.NEXT_PUBLIC_PROJECT_NAME] as IBurgerMenuItem[];

export const notAuthItems = {
    epn: [
        {
            key: 'Webmasters',
            type: BurgerMenuItemTypes.link,
            id: 'MenuMobileWebmastersDropdownEn',
            href: routes.home,
        },
        {
            key: 'Advertisers',
            type: BurgerMenuItemTypes.link,
            id: 'MenuMobileAdvertiserLink',
            href: routes.cpa,
        },
        // {
        //     key: 'Affiliate programs',
        //     type: BurgerMenuItemTypes.link,
        //     id: 'MenuMobileAffiliateProgramsLink',
        //     href: routes.offers,
        // },
        {
            key: 'Help',
            type: BurgerMenuItemTypes.link,
            id: 'MenuMobileHelpLink',
            href: oldCabinetLinks.faq,
        },
        {
            type: BurgerMenuItemTypes.dropdown,
            key: 'Cryptovertical',
            id: 'MenuCryptoDropdown',
            icon: newIcon,
            values: [
                {
                    key: 'Webmasters',
                    id: 'Webmasters',
                    href: routes.crypto,
                },
                {
                    key: 'Advertiser',
                    id: 'Advertiser',
                    href: routes.cryptoadvertiser,
                },
            ],
        },
    ],
    epncpa: [
        {
            key: 'Webmasters',
            type: BurgerMenuItemTypes.link,
            id: 'MenuMobileWebmastersDropdownEn',
            href: routes.home,
        },
        {
            key: 'Advertisers',
            type: BurgerMenuItemTypes.link,
            id: 'MenuMobileAdvertiserLink',
            href: routes.cpa,
        },
        {
            key: 'Affiliate programs',
            type: BurgerMenuItemTypes.link,
            id: 'MenuMobileAffiliateProgramsLink',
            href: routes.offers,
        },
    ],
}[process.env.NEXT_PUBLIC_PROJECT_NAME] as IBurgerMenuItem[];
