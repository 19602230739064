import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const availableSizeType = ['xs', 'sm', 'md', 'lg', 'xl', 'initial'];

const classMap = {
    xs: {
        0: 'xs:hidden',
        1: 'xs:block xs:w-[8.333333%] xs:max-w-[8.333333%]',
        2: `xs:block xs:w-[16.666667%] xs:max-w-[16.666667%]`,
        3: 'xs:block xs:w-[25%] xs:max-w-[25%]',
        4: 'xs:block xs:w-[33.333333%] xs:max-w-[33.333333%]',
        5: 'xs:block xs:w-[41.666667%] xs:max-w-[41.666667%]',
        6: 'xs:block xs:w-[50%] xs:max-w-[50%]',
        7: 'xs:block xs:w-[58.333333%] xs:max-w-[58.333333%]',
        8: 'xs:block xs:w-[66.666667%] xs:max-w-[66.666667%]',
        9: 'xs:block xs:w-[75%] xs:max-w-[75%]',
        10: 'xs:block xs:w-[83.333333%] xs:max-w-[83.333333%]',
        11: 'xs:block xs:w-[91.666667%] xs:max-w-[91.666667%]',
        12: 'xs:block xs:w-[100%] xs:max-w-[100%]',
    },
    sm: {
        0: 'sm:hidden',
        1: 'sm:block sm:w-[8.333333%] sm:max-w-[8.333333%]',
        2: `sm:block sm:w-[16.666667%] sm:max-w-[16.666667%]`,
        3: 'sm:block sm:w-[25%] sm:max-w-[25%]',
        4: 'sm:block sm:w-[33.333333%] sm:max-w-[33.333333%]',
        5: 'sm:block sm:w-[41.666667%] sm:max-w-[41.666667%]',
        6: 'sm:block sm:w-[50%] sm:max-w-[50%]',
        7: 'sm:block sm:w-[58.333333%] sm:max-w-[58.333333%]',
        8: 'sm:block sm:w-[66.666667%] sm:max-w-[66.666667%]',
        9: 'sm:block sm:w-[75%] sm:max-w-[75%]',
        10: 'sm:block sm:w-[83.333333%] sm:max-w-[83.333333%]',
        11: 'sm:block sm:w-[91.666667%] sm:max-w-[91.666667%]',
        12: 'sm:block sm:w-[100%] sm:max-w-[100%]',
    },
    md: {
        0: 'md:hidden',
        1: 'md:block md:w-[8.333333%] md:max-w-[8.333333%]',
        2: `md:block md:w-[16.666667%] md:max-w-[16.666667%]`,
        3: 'md:block md:w-[25%] md:max-w-[25%]',
        4: 'md:block md:w-[33.333333%] md:max-w-[33.333333%]',
        5: 'md:block md:w-[41.666667%] md:max-w-[41.666667%]',
        6: 'md:block md:w-[50%] md:max-w-[50%]',
        7: 'md:block md:w-[58.333333%] md:max-w-[58.333333%]',
        8: 'md:block md:w-[66.666667%] md:max-w-[66.666667%]',
        9: 'md:block md:w-[75%] md:max-w-[75%]',
        10: 'md:block md:w-[83.333333%] md:max-w-[83.333333%]',
        11: 'md:block md:w-[91.666667%] md:max-w-[91.666667%]',
        12: 'md:block md:w-[100%] md:max-w-[100%]',
    },
    lg: {
        0: 'lg:hidden',
        1: 'lg:block lg:w-[8.333333%] lg:max-w-[8.333333%]',
        2: `lg:block lg:w-[16.666667%] lg:max-w-[16.666667%]`,
        3: 'lg:block lg:w-[25%] lg:max-w-[25%]',
        4: 'lg:block lg:w-[33.333333%] lg:max-w-[33.333333%]',
        5: 'lg:block lg:w-[41.666667%] lg:max-w-[41.666667%]',
        6: 'lg:block lg:w-[50%] lg:max-w-[50%]',
        7: 'lg:block lg:w-[58.333333%] lg:max-w-[58.333333%]',
        8: 'lg:block lg:w-[66.666667%] lg:max-w-[66.666667%]',
        9: 'lg:block lg:w-[75%] lg:max-w-[75%]',
        10: 'lg:block lg:w-[83.333333%] lg:max-w-[83.333333%]',
        11: 'lg:block lg:w-[91.666667%] lg:max-w-[91.666667%]',
        12: 'lg:block lg:w-full lg:max-w-[100%]',
    },
    xl: {
        0: 'xl:hidden',
        1: 'xl:block xl:w-[8.333333%] xl:max-w-[8.333333%]',
        2: `xl:block xl:w-[16.666667%] xl:max-w-[16.666667%]`,
        3: 'xl:block xl:w-[25%] xl:max-w-[25%]',
        4: 'xl:block xl:w-[33.333333%] xl:max-w-[33.333333%]',
        5: 'xl:block xl:w-[41.666667%] xl:max-w-[41.666667%]',
        6: 'xl:block xl:w-[50%] xl:max-w-[50%]',
        7: 'xl:block xl:w-[58.333333%] xl:max-w-[58.333333%]',
        8: 'xl:block xl:w-[66.666667%] xl:max-w-[66.666667%]',
        9: 'xl:block xl:w-[75%] xl:max-w-[75%]',
        10: 'xl:block xl:w-[83.333333%] xl:max-w-[83.333333%]',
        11: 'xl:block xl:w-[91.666667%] xl:max-w-[91.666667%]',
        12: 'xl:block xl:w-full xl:max-w-[100%]',
    },
    initial: {
        0: 'hidden',
        1: 'w-[8.333333%]',
        2: `w-[16.666667%]`,
        3: 'w-[25%]',
        4: 'w-[33.333333%]',
        5: 'w-[41.666667%]',
        6: 'w-[50%]',
        7: 'w-[58.333333%]',
        8: 'w-[66.666667%]',
        9: 'w-[75%]',
        10: 'w-[83.333333%]',
        11: 'w-[91.666667%]',
        12: 'w-[100%]',
    },
};

const getClassesBySize = (size, sizeValue) => {
    if (sizeValue === null || !availableSizeType.includes(size) || sizeValue < 0 || sizeValue > 12)
        return '';
    return classMap[size][sizeValue];
};

function BaseGrid(props) {
    const {
        initial = null,
        xs = null,
        sm = null,
        md = null,
        lg = null,
        xl = null,
        className = '',
        children,
        item,
        container,
    } = props;
    const rowClasses = clsx('flex md:mx-0 w-full', className);
    const itemClasses = clsx(
        'px-[8px] flex-grow',
        xs === 0 || sm === 0 || md === 0 || lg === 0 ? 'hidden' : '',
        getClassesBySize('initial', initial),
        getClassesBySize('xs', xs),
        getClassesBySize('sm', sm),
        getClassesBySize('md', md),
        getClassesBySize('lg', lg),
        getClassesBySize('xl', xl),
        className,
    );

    const config = {
        container: <div className={rowClasses}>{children}</div>,
        item: <div className={itemClasses}>{children}</div>,
    };

    const type = (container && 'container') || (item && 'item');

    return config[type];
}

BaseGrid.propTypes = {
    container: PropTypes.bool,
    className: PropTypes.string,
    item: PropTypes.bool,
    initial: PropTypes.number,
    children: PropTypes.node,
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
};

export default BaseGrid;
