import ky, { NormalizedOptions, BeforeRequestHook } from 'ky';
import { clearSession, refreshToken, getAccessToken, getRefreshToken } from '@utils/auth';

export interface ExtendedBeforeRequestHook extends BeforeRequestHook {
    optionalAuth?: boolean;
}

export declare type CustomBeforeRequestHook = (
    request: Request,
    options: NormalizedOptions & ExtendedBeforeRequestHook,
) => Request | Response | void | Promise<Request | Response | void>;

let refreshPromise;

const refresh = (client: typeof ky): CustomBeforeRequestHook => async (_req, options) => {
    const jwtRefreshToken = getRefreshToken();
    const clearRefreshPromise = () => {
        refreshPromise = null;
    };

    // Если ручка закрыта авторизацией, токена нет, то логаут
    if (!jwtRefreshToken && !options.optionalAuth) {
        clearSession();
        return;
    }

    // Проверяем просрочен ли access_token, пропустить если всё ок
    if (!getAccessToken()) {
        // Иду за новыми токенами, если токен refresh не протух
        if (jwtRefreshToken && !refreshPromise) {
            // Если refresh ещё не добавлен, добавить
            refreshPromise = refreshToken(client, clearRefreshPromise);
        }
    }

    // Если есть промис, ждем его результат для всех запросов
    if (refreshPromise) {
        try {
            await refreshPromise;
        } catch (error) {
            console.error(error);
            clearSession();
        }
    }
};

export default refresh;
