import { useRouter } from 'next/router';
import useI18n, { LocaleTypes } from '@i18n';

import routes from '@config/routes.json';

import BaseContainer from '@base/BaseContainer';
import BaseGrid from '@base/BaseGrid';
import Header from './Header';
import Footer from './Footer';
import TabPanel from './TabPanel';

import { ILayoutProps } from './default';
import { ITabPanelItem, ScreenTypes } from './TabPanel/interfaces';

const oldCabinetLinks = JSON.parse(process.env.NEXT_PUBLIC_OLD_CABINET_LINKS);

const FaqLayout: React.FC<ILayoutProps> = ({ children, isAuth }) => {
    const { t, locale } = useI18n();
    const { pathname } = useRouter();
    const page = pathname.split('/')[3];

    const tabList: ITabPanelItem[] = [
        {
            title: t('Where to begin?'),
            href: oldCabinetLinks.help,
            screen: ScreenTypes.always,
            active: !page,
        },
        {
            title: t('Tools'),
            href: oldCabinetLinks.tools,
            screen: ScreenTypes.always,
            active: page === 'tools',
        },
        {
            title: t('Statistics'),
            href: oldCabinetLinks.stats,
            screen: ScreenTypes.xs,
            active: page === 'stats',
        },
        {
            title: t('Purses and withdrawal of money'),
            href: oldCabinetLinks.purses,
            screen: ScreenTypes.sm,
            active: page === 'purses',
        },
        {
            title: t('Why did not the order be credited?'),
            href: oldCabinetLinks.problems,
            screen: ScreenTypes.md,
            active: page === 'problems',
        },
        {
            title: t('QA'),
            href: oldCabinetLinks.faq,
            screen: ScreenTypes.xl,
            active: page === 'faq',
        },
        {
            title: t('SelfEmployed'),
            href: routes.faq.selfEmployed,
            screen: ScreenTypes.xl,
            active: page === 'self-employed',
        },
        {
            title: t('Telegram-bot'),
            href: oldCabinetLinks.telegramBot,
            screen: ScreenTypes.never,
            active: page === 'telegram-bot',
        },
    ];

    if (locale === LocaleTypes.ru) {
        tabList.push({
            title: t('Learning'),
            href: routes.faq.learning,
            screen: ScreenTypes.never,
            active: page === 'learning',
        });

        tabList.push({
            title: 'Продвижение',
            href: '',
            screen: ScreenTypes.never,
            active: false,
            childrens: [
                {
                    title: 'Как выбрать нишу ...',
                    href: 'https://epn.bz/info/kak-vybrat-temu-dlya-bloga/',
                    screen: ScreenTypes.never,
                },
                {
                    title: 'Как наполнять блог ...',
                    href: 'https://epn.bz/info/o-chem-pisat-v-bloge/',
                    screen: ScreenTypes.never,
                },
                {
                    title: 'Способы взаимодействия ...',
                    href: 'https://epn.bz/info/kak-popast-v-rekomendacii-instagram/',
                    screen: ScreenTypes.never,
                },
                {
                    title: 'Подготовка площадок ...',
                    href: 'https://epn.bz/info/planirovanie-reklamnoj-kampanii/',
                    screen: ScreenTypes.never,
                },
                {
                    title: 'Реклама в соцсетях ...',
                    href: 'https://epn.bz/info/kak-obojti-bany-v-facebook/',
                    screen: ScreenTypes.never,
                },
                {
                    title: 'Рекламные кабинеты ...',
                    href: 'https://epn.bz/info/kak-nastroit-reklamu-v-yandekse-i-google/',
                    screen: ScreenTypes.never,
                },
                {
                    title: 'Реклама у блогеров ...',
                    href: 'https://epn.bz/info/reklama-u-blogerov/',
                    screen: ScreenTypes.never,
                },
                {
                    title: 'Базовые параметры SEO ...',
                    href: 'https://epn.bz/info/osnovy-seo/',
                    screen: ScreenTypes.never,
                },
            ],
        });
    }

    return (
        <>
            <Header isAuth={isAuth} />
            <TabPanel tabList={tabList} />
            {/* хак: обертка в лишний див для фикса тк меняется класс на toastify */}
            <div>
                <BaseContainer>
                    <BaseGrid container>{children}</BaseGrid>
                </BaseContainer>
            </div>
            <Footer />
        </>
    );
};

export default FaqLayout;
