import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle';
import { faBan } from '@fortawesome/pro-regular-svg-icons/faBan';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import styles from './styles.module.scss';

export const CloseButtonIcon = () => <FontAwesomeIcon icon={faTimes} />;

// возможно нужно систематизировать дизайн разных уведомлений в соответствии с info
// https://www.figma.com/file/qAEq1WvqHeg423QEZvFJiH/UI-kit-WM?node-id=2086%3A65
export const StyledToastContainer = props => (
    <ToastContainer
        {...props}
        className={`${styles.toast} sm:py-[24px] sm:px-[16px] md:p-[24px] md:w-auto min-w-[320px]`}
    />
);

// info message icon: faInfoCircle
// warning message icon: faExclamationCircle
// error message icon: faBan
// success message icon: faCheckCircle

const icons = {
    info: faInfoCircle,
    warn: faExclamationCircle,
    error: faBan,
    success: faCheckCircle,
};

const BaseToast = ({ text, type }) => (
    <div>
        <FontAwesomeIcon icon={icons[type]} className="mx-[8px]" />
        {text}
    </div>
);

BaseToast.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired, // - node если нужен таймер например
    type: PropTypes.string.isRequired,
};

export default BaseToast;
