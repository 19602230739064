import useI18n, { LocaleTypes } from '@i18n';

import RuIcon from '@layout/Footer/I18nLink/icons/ru.svg';
import EnIcon from '@layout/Footer/I18nLink/icons/en.svg';

import styles from './Footer.module.scss';

import mastercardLogo from './img/mastercard.svg';
import qiwiLogo from './img/qiwi.svg';
import tonLogo from './img/ton.svg';
import visaLogo from './img/visa.svg';
import webmoneyLogo from './img/wm.svg';
import youmoneyLogo from './img/yoo.svg';

const config = {
    [LocaleTypes.en]: {
        name: 'Русский',
        i18nName: LocaleTypes.ru,
        icon: RuIcon,
    },
    [LocaleTypes.ru]: {
        name: 'English',
        i18nName: LocaleTypes.en,
        icon: EnIcon,
    },
};

const Footer: React.FC = () => {
    const { t, locale } = useI18n();

    const changeLang = () => {
        const path = window.location.pathname.replace(
            `/${locale}`,
            locale === 'ru' ? '/en' : '/ru',
        );
        window.location.pathname = path;
    };

    return (
        <footer className={styles.footer}>
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <div>
                        <ul>
                            <li>
                                {t('Support')} <a href="mailto:support@epn.bz">support@epn.bz</a>
                            </li>
                            <li>
                                {t('Your suggestions')}{' '}
                                <a href="mailto:crypto@epn.bz">crypto@epn.bz</a>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.description}>
                        <div
                            className={styles.changeLang}
                            onClick={changeLang}
                            onKeyDown={changeLang}
                            role="button"
                            tabIndex={0}
                        >
                            <span className="mr-1">{config[locale].name}</span>
                            <img src={config[locale].icon} className="w-6 h-4" alt="" />
                        </div>
                        <p className={styles.descriptionDesktop}>
                            @ 2013-{new Date().getFullYear()} ePN Development Team Future Tech
                            <br />
                            Future Tech Affiliate Limited(Suite 3, First Floor, La Ciotat Buidling,
                            Mont Fleuri, Mahe, Seychelles, 237865)
                        </p>
                    </div>
                    <div className={styles.payments}>
                        <img src={youmoneyLogo} alt="youmoneyLogo" />
                        <img src={mastercardLogo} alt="mastercard" />
                        <img src={visaLogo} alt="visaLogo" />
                        <img src={qiwiLogo} alt="qiwiLogo" />
                        <img src={webmoneyLogo} alt="webmoneyLogo" />
                        <img src={tonLogo} alt="tonLogo" />
                    </div>
                    <div className={styles.descriptionMobile}>
                        <p>
                            @ 2013-{new Date().getFullYear()} ePN Development Team Future Tech
                            <br />
                            Affiliate Limited(Suite 3, First Floor, La Ciotat Buidling, Mont Fleuri,
                            Mahe, Seychelles, 237865)
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
