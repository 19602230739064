import { clearSession, refreshToken, getAccessToken, getRefreshToken } from '@utils/auth';

let promise = null;

const AUTH_ERRORS = [401];

const refresh = client => async (req, options, res) => {
    const { status } = res || {};

    const clearRefreshPromise = () => {
        promise = null;
    };

    if (status === 403) {
        const accessToken = getAccessToken();
        const refreshTokenA = getRefreshToken();
        if (accessToken && refreshTokenA) {
            window.location.href = '/ru/forbidden';
            client.stop();
        }
    }

    if (!AUTH_ERRORS.includes(status) || options.ignoreRefresh) {
        return res;
    }

    if (!promise) {
        promise = refreshToken(client, clearRefreshPromise);
    }

    try {
        await promise;
        if (!options.withoutReturn) {
            return client(req, {
                ...options,
                retry: true,
            });
        }
    } catch {
        clearSession();
    }

    return res;
};

export default refresh;
