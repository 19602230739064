import Header from '../Header';
import Footer from './Footer';
import { ILayoutProps } from '../default';

const CryptoLayout: React.FC<ILayoutProps> = ({ children, isAuth }) => {
    return (
        <div>
            <Header isAuth={isAuth} />
            {children}
            <Footer />
        </div>
    );
};

export default CryptoLayout;
