import BaseContainer from '@base/BaseContainer';
import Footer from './Footer';
import Alerts from './Alerts';
import Header from './Header';

export interface ILayoutProps {
    isAuth: boolean | null;
}

const DefaultLayout: React.FC<ILayoutProps> = ({ children, isAuth }) => {
    return (
        <>
            <Header isAuth={isAuth} />
            {/* TODO: убрать хак, обертка в лишний див для фикса тк меняется класс на toastify */}
            <div className="mt-4 xs:mt-10">
                <BaseContainer>{children}</BaseContainer>
            </div>
            <Footer />
            {isAuth && <Alerts />}
        </>
    );
};

export default DefaultLayout;
