import React from 'react';
import PropTypes from 'prop-types';

function BaseContainer({ children }) {
    return (
        <div className="relative h-full px-2 mx-auto box-border xs:w-full sm:w-full md:container-w-md sm:px-4 lg:container-w-lg xl:container-w-xl">
            {children}
        </div>
    );
}

BaseContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

export default BaseContainer;
