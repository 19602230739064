import externalLinks from '@config/external-links.json';
import routes from '@config/routes.json';

import { IFooterLinkItem } from './interfaces';

const oldCabinetLinks = JSON.parse(process.env.NEXT_PUBLIC_OLD_CABINET_LINKS);

export default {
    epn: [
        {
            key: 'Webmasters',
            id: 'FooterAffiliateLink',
            href: routes.home,
        },
        {
            key: 'News',
            id: 'FooterNewsLink',
            href: oldCabinetLinks.news,
        },
        {
            key: 'Rules',
            id: 'FooterRulesLink',
            href: oldCabinetLinks.rules,
        },
        // {
        //     key: 'Privacy',
        //     id: 'FooterPrivacyLink',
        //     href: oldCabinetLinks.privacy,
        // },
        {
            key: 'Help',
            id: 'FooterHelpLink',
            href: oldCabinetLinks.help,
        },
        // {
        //     key: 'Affiliate Programs',
        //     id: 'FooterPartnersLink',
        //     href: oldCabinetLinks.partners,
        // },
        {
            key: 'EPN Affiliate',
            id: 'FooterAffiliateWebmasterLink',
            href: routes.cpa,
        },
        // {
        //     key: 'blog',
        //     id: 'FooterBlogLink',
        //     href: externalLinks.blog.info,
        //     isVisible: locale === LocaleTypes.ru,
        // },
        {
            key: 'Cashback',
            id: 'FooterCashbackLink',
            href: 'https://backit.me/:locale',
        },
        {
            key: 'API Documentation',
            id: 'FooterApiLink',
            href: externalLinks.apiDoc,
        },
    ],
    epncpa: [
        {
            key: 'Webmasters',
            id: 'FooterAffiliateLink',
            href: routes.home,
        },
        {
            key: 'EPN Affiliate',
            id: 'FooterAffiliateWebmasterLink',
            href: routes.cpa,
        },
        {
            key: 'Cashback',
            id: 'FooterCashbackLink',
            href: 'https://cashbackit.me/',
        },
    ],
}[process.env.NEXT_PUBLIC_PROJECT_NAME] as IFooterLinkItem[];
